@import "react-datepicker/dist/react-datepicker.css";
@import "react-sliding-pane/dist/react-sliding-pane.css";
@import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

body {
    margin: 0;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-lightgray {
    background-color: #eeeeee;
}

.w-25px {
    width: 25px;
}

.w-15px {
    width: 15px;
}

.w-150px {
    width: 150px;
}

.w-120px {
    width: 120px;
}

.w-100px {
    width: 100px;
}

.w-200px {
    width: 200px;
}

.h-50px {
    height: 50px;
}

.h-100px {
    height: 100px;
}

.h-200px {
    height: 200px;
}

.h-400px {
    height: 400px;
}

.mh-50px {
    min-height: 50px;
}

.mh-100px {
    min-height: 100px;
}

.mh-150px {
    min-height: 150px;
}

.mh-400px {
    min-height: 400px;
}

.h-150px {
    height: 150px;
}

.w-100pct {
    width: 100%;
}

.w-75pct {
    width: 75%;
}

.w-50pct {
    width: 50%;
}

.w-25pct {
    width: 25%;
}

.w-20pct {
    width: 20%;
}

.w-16pct {
    width: 16.66%;
}

.w-12pct {
    width: 12.5%;
}

.range-slider__wrap {
    flex: 1;
}

.hover-text-info:hover {
    color: #3498db !important;
}

.carousel-item > img {
    width: 100%;
}

.panel-footer {
    background-color: #ececec;
}

.small .input-group-text {
    font-size: 0.875em;
    padding: 0.35rem 0.75rem;
}

.fast-transition {
    transition: transform 0.2s;
}

a.nav-link.active {
    font-weight: bold;
}

.border-radius-10 {
    border-radius: 10px;
}

.slide-pane__overlay {
    z-index: 2000;
}

.slide-pane__content {
    padding: 0 !important;
}

div.login-wallpaper {
    height: 100vh;
    background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%) url(../public/bg-masthead.jpg) no-repeat center;
    background-size: cover;
}

.text-disabled {
    color: #999;
}

.react-datepicker {
    width: 100%;
    font-size: 1.1rem;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__bg {
    background-color: #DE9B40;
}

div.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 1.5rem;
}

@media only screen and (min-width: 567px) {

    .react-datepicker__day {
        width: 3rem;
        line-height: 3rem;
    }

    .react-datepicker__day-name {
        width: 3rem;
    }

}

